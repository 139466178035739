import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);

  // Bu useEffect bütün sayfalarda scroll animation yapmamızı sağlıyor.!
  useEffect(() => {
    function reveal() {
      var reveals = document.querySelectorAll(".reveal");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementBottom = reveals[i].getBoundingClientRect().bottom;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 300;

        if (
          elementTop < windowHeight - elementVisible &&
          elementBottom > windowHeight - elementVisible
        ) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }

      var links = document.querySelectorAll(".navLink");

      for (let i = 0; i < links.length; i++) {
        var linkTarget = document.querySelector(links[i].getAttribute("href"));
        if (linkTarget.getAttribute("class").includes("active")) {
          links[i].classList.add("active");
        } else {
          links[i].classList.remove("active");
        }
      }
    }

    window.addEventListener("scroll", reveal);

    return () => {
      window.removeEventListener("scroll", reveal);
    };
  }, []);

  return <>{props.children}</>;
};

export default ScrollToTop;
