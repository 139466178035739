import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "../assets/css/AccordionCompanent.css";
import soru from "../assets/images/soru.png";

function AccordionCompanent() {
  return (
    <Accordion>
      <Accordion.Item
        eventKey="0"
        style={{ borderRadius: "15px", boxShadow: "5px 5px 5px #ccc" }}
      >
        <Accordion.Header>
          <img
            style={{ width: "3rem", marginRight: "1rem" }}
            src={soru}
            alt=""
          />
          <h5>Vale nedir?</h5>
        </Accordion.Header>
        <Accordion.Body
          style={{ marginLeft: "4rem", marginTop: "-1.2rem", color: "#000" }}
        >
          Şoför ihtiyacınız doğrultusunda size yönlendirilmiş kişisel bir
          sürücüdür. <br /> Bu sürücüler vale hizmeti hakkında tüm eğitimleri ve
          gerekli sertifikaları almış kişilerdir. 
        </Accordion.Body>
      </Accordion.Item>
      <div style={{ height: "1rem" }}></div>

      <Accordion.Item
        eventKey="1"
        style={{ borderRadius: "15px", boxShadow: "5px 5px 5px #ccc" }}
      >
        <Accordion.Header style={{ fontSize: "2rem", color: "#000" }}>
          <img
            style={{ width: "3rem", marginRight: "1rem" }}
            src={soru}
            alt=""
          />
          <h5>Yolculuk ücretlendirilmesi nasıl yapılır?</h5>
        </Accordion.Header>
        <Accordion.Body
          style={{ marginLeft: "4rem", marginTop: "-1.2rem", color: "#000" }}
        >
          Bulunduğunuz ve gideceğiniz bölge göz önünde bulundurularak açılış ücreti belirlenir. <br /> Açılış ücretine ek olarak; Katedilen her kilometre, Bekleme yapılan her dakika, Güzergah üzerinde uğrama yapılan her lokasyon yolculuk ücretine dahil edilir.

        </Accordion.Body>
      </Accordion.Item>
      <div style={{ height: "1rem" }}></div>

      <Accordion.Item
        eventKey="2"
        style={{ borderRadius: "15px", boxShadow: "5px 5px 5px #ccc" }}
      >
        <Accordion.Header style={{ fontSize: "2rem" }}>
          <img
            style={{ width: "3rem", marginRight: "1rem" }}
            src={soru}
            alt=""
          />
          <h5>Valesepeti valeleri ne tür araçlar kullanabilir?</h5>
        </Accordion.Header>
        <Accordion.Body
          style={{ marginLeft: "4rem", marginTop: "-1.2rem", color: "#000" }}
        >
          Valesepeti ekibi, kara ve su araçları da dahil olmak üzere birçok araç
          kullanabilmektedir.
        </Accordion.Body>
      </Accordion.Item>
      <div style={{ height: "1rem" }}></div>

      <Accordion.Item
        eventKey="3"
        style={{ borderRadius: "15px", boxShadow: "5px 5px 5px #ccc" }}
      >
        <Accordion.Header style={{ fontSize: "2rem" }}>
          <img
            style={{ width: "3rem", marginRight: "1rem" }}
            src={soru}
            alt=""
          />
          <h5>Valesepeti valeleri yeterli eğitime sahip mi?</h5>
        </Accordion.Header>
        <Accordion.Body
          style={{ marginLeft: "4rem", marginTop: "-1.2rem", color: "#000" }}
        >
          Valesepeti şoför adayları işe alım sırasında bünyesinde bulunan sürüş okulunda temel sürüş ve trafik kurallarını içeren bir sınava tabi tutulur. Sınavdan başarılı sonuç alan adaylara ileri sürüş eğitimi verilir ve bu eğitimden de başarılı sonuç alan adaylar Valesepeti bünyesine katılmış olur. {" "}
          <b>
          Bunlara ek olarak vale ekibimiz, temel iletişim ve kriz yönetimi eğitimi de almaktadır.
          </b>
        </Accordion.Body>
      </Accordion.Item>
      <div style={{ height: "1rem" }}></div>
      <Accordion.Item
        eventKey="4"
        style={{ borderRadius: "15px", boxShadow: "5px 5px 5px #ccc" }}
      >
        <Accordion.Header style={{ fontSize: "2rem" }}>
          <img
            style={{ width: "3rem", marginRight: "1rem" }}
            src={soru}
            alt=""
          />
          <h5>Valesepeti valeleri güvenilir midir?</h5>
        </Accordion.Header>
        <Accordion.Body
          style={{ marginLeft: "4rem", marginTop: "-1.2rem", color: "#000" }}
        >
          Valesepeti valeleri işe alım sırasında sabıka kontrolü de dahil olmak üzere detaylı bir mülakattan geçer.  {" "}
          <b>
          (27 yaş sınırı ve minimum 5 senelik ehliyet yaşı, sürüş tecrübesine dayalı iş tecrübesi, vb. gibi)
          </b>
          Herhangi bir suça karışmadığı ve karışmayacağı kanaatine varılan kişiler Valesepeti’nde çalışmaya hak kazanır.
        </Accordion.Body>
      </Accordion.Item>
      <div style={{ height: "1rem" }}></div>
      <Accordion.Item
        eventKey="5"
        style={{ borderRadius: "15px", boxShadow: "5px 5px 5px #ccc" }}
      >
        <Accordion.Header style={{ fontSize: "2rem" }}>
          <img
            style={{ width: "3rem", marginRight: "1rem" }}
            src={soru}
            alt=""
          />
          <h5>Valesepeti’nde ödeme nasıl yapılır?</h5>
        </Accordion.Header>
        <Accordion.Body
          style={{ marginLeft: "4rem", marginTop: "-1.2rem", color: "#000" }}
        >
          Valesepeti’nde ödemeler ve şoför bahşişleri nakit ve havale aracılığıyla yapılır.
        </Accordion.Body>
      </Accordion.Item>
      
      <div style={{ height: "1rem" }}></div>
      <Accordion.Item
        eventKey="7"
        style={{ borderRadius: "15px", boxShadow: "5px 5px 5px #ccc" }}
      >
        <Accordion.Header style={{ fontSize: "2rem" }}>
          <img
            style={{ width: "3rem", marginRight: "1rem" }}
            src={soru}
            alt=""
          />
          <h5>Valesepeti valesinin ne zaman geleceğini nasıl bileceğim?</h5>
        </Accordion.Header>
        <Accordion.Body
          style={{ marginLeft: "4rem", marginTop: "-1.2rem", color: "#000" }}
        >
          Valesepeti’nde bir yolculuk oluşturulduğunda sürücünüz belirlenen süre içinde  hizmetinize hazır olur. 
 <br />
          <b>
          Acil çağrılarda 30 dakika içinde valeniz size ulaşır. (Bu özellik ek ücrete tabidir.)
          </b>
        </Accordion.Body>
      </Accordion.Item>
      <div style={{ height: "1rem" }}></div>
      <Accordion.Item
        eventKey="8"
        style={{ borderRadius: "15px", boxShadow: "5px 5px 5px #ccc" }}
      >
        <Accordion.Header style={{ fontSize: "2rem" }}>
          <img
            style={{ width: "3rem", marginRight: "1rem" }}
            src={soru}
            alt=""
          />
          <h5>Valesepeti’nde yolculuğu iptal etmenin şartları nelerdir?</h5>
        </Accordion.Header>
        <Accordion.Body
          style={{ marginLeft: "4rem", marginTop: "-1.2rem", color: "#000" }}
        >
          Valesepeti’nde oluşturulan yolcuğun tahmini tutarı yolculuk öncesinde belirlenir. Yolculuğun iptal edilmesi durumunda açılış ücreti tarafınıza yansıtılır.
        </Accordion.Body>
      </Accordion.Item>
      <div style={{ height: "1rem" }}></div>
    </Accordion>
  );
}

export default AccordionCompanent;
