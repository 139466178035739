import React from "react";
import "../assets/css/Sss.css";
import AccordionCompanent from "./AccordionCompanent";

const Sss = () => {
  return (
    <div id="sss" className="reveal">
      <div className="sss">
        <h2 className="sssTitle">Sıkça Sorulan Sorular</h2>
        <AccordionCompanent />
      </div>
    </div>
  );
};

export default Sss;
