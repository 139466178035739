import React from "react";
import "../assets/css/ComingSoon.css";
import comingSoon from "../assets/images/comingSoon.png";

const ComingSoon = () => {
  return (
    <>
      <div id="comingSoon" className="comingSoon">
        <img className="comingSoonImg" src={comingSoon} alt="" />
      </div>
    </>
  );
};

export default ComingSoon;
