import React from "react";
import "../assets/css/Home.css";
import Contact from "./Contact";
import Hero from "./Hero";
import Sss from "./Sss";
import Who from "./Who";
import Why from "./Why";

const Home = () => {
  
  // MOBILE NAVBAR
  return (
    <>
      <Hero />
      <Who />
      <Why />
      {/* <How /> */}
      <Sss />
      <Contact />
      <div class="whatsapp">
        <a
          alt="wp-button"
          href="https://api.whatsapp.com/send?phone=905451553459&text=Vale%20hizmeti%20almak%20istiyorum"
          className="wp"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
        </a>
      </div>
    </>
  );
};

export default Home;
