import React, { useState, useEffect } from "react";
import "../assets/css/Why.css";
import why from "../assets/images/why.png";
import whyMobile from "../assets/images/whyMobile.png";

const Why = () => {
  const [innerWidth, setInnerWidth] = useState();

  useEffect(() => {
    setInnerWidth(window.innerWidth);
  }, []);

  // MOBILE NAVBAR
  if (innerWidth && innerWidth < 768) {
    return (
      <div id="why" className="reveal">
        <div className="why">
          <h2 className="whyTitle">Neden Valesepeti?</h2>
          <p>
            Vale çağının kurumsal yüzü. Vale hizmetinin profesyonel şekli.
            Saatlik valeler ile kendinizi şımartın. <br /> VIP Vale çağırarak
            misafirlerinize unutulmaz bir deneyim sunun.
          </p>
          <img className="whyImg" src={whyMobile} alt="" />
        </div>
      </div>
    );
  }
  // WEB NAVBAR
  else {
    return (
      <div id="why" className="reveal">
        <div className="why">
          <h2 className="whyTitle">Neden Valesepeti?</h2>
          <p>
            Vale çağının kurumsal yüzü. Vale hizmetinin profesyonel şekli.
            Saatlik valeler ile kendinizi şımartın. <br /> VIP Vale çağırarak
            misafirlerinize unutulmaz bir deneyim sunun.
          </p>
          <img className="whyImg" src={why} alt="" />
        </div>
      </div>
    );
  }
};

export default Why;
