import React from "react";
import "../assets/css/Kvkk.css";
import kvkk from "../assets/images/kvkk.png";

const Kvkk = () => {
  return (
    <div id="kvkk">
      <h2 className="kvkkTitle">KVKK</h2>
      <img className="kvkk" src={kvkk} alt="" />
      <div className="kvkkContainer">
        <br />
        <p className="kvkkSubTitle">
          Kişisel verilerinizle ilgili haklarınızı nasıl kullanabilirsiniz?
        </p>{" "}
        <br />
        <p>
          KVKK’nın 11. maddesinde düzenlenen haklarınızı kullanmak ve
          taleplerinizi Valesepeti’ne iletmek için aşağıdaki yöntemlerden birini
          tercih edebilirsiniz: <br />• Talebinizi, sistemlerimizde kayıtlı
          e-posta adresinizi kullanarak{" "}
          <span className="kvkkSpan">
            <a className="kvkkSpan" href="mailto:info@valesepeti.com">
              info@valesepeti.com
            </a>
          </span>{" "}
          adresine, <br />• Talebinizi, kayıtlı elektronik posta (KEP) adresiniz
          aracılığıyla{" "}
          <span className="kvkkSpan">
            <a className="kvkkSpan" href="mailto:info@valesepeti.com">
              info@valesepeti.com
            </a>
          </span>{" "}
          adresine güvenli elektronik imza veya mobil imza aracılığıyla, <br />•
          Talebinizi, kimliğinizi tevsik edici belgeleri de ekleyerek yazılı
          olarak,{" "}
          <span className="kvkkSpan">
            <a className="kvkkSpan" href="mailto:info@valesepeti.com">
              info@valesepeti.com
            </a>
          </span>{" "}
          adresine gönderebilirsiniz. <br />
          Valesepeti, KVKK’nın 13. maddesine uygun olarak, başvuru
          taleplerinizi, talebin niteliğine göre ve en geç 30 (otuz) gün içinde
          sonuçlandıracaktır. İşlemin maliyet gerektirmesi halinde, Kişisel
          Verileri Koruma Kurulu tarafından belirlenen tarife uygulanacaktır.
          Talebinizin reddedilmesi halinde, ret nedeni/nedenleri yine en geç 30
          (otuz) gün içinde tarafınıza iletilecektir.
        </p>{" "}
        <br />
        <p className="kvkkSubTitle">Aydınlatma Metni Hakkında</p> <br />
        <p>
          İşbu Aydınlatma Metni, değişen şartlara ve hukuki düzenlemelere uyum
          sağlamak amacıyla güncellenebilecektir. Şirketimiz tarafından
          yapılacak güncellemeler Uygulama üzerinden bildirilecektir. <br />
          İlk aldığımız tedbirler ve kişisel verileri işlerken uyum sağladığımız
          kurallar hakkında detaylı bilgi için ayrıca{" "}
          <span className="kvkkSpan">
            <a className="kvkkSpan" href="#kvkk">
              Kişisel Verilerin İşlenmesi ve Korunması Politikamıza
            </a>
          </span>{" "}
          göz atmanızı öneririz.
        </p>
      </div>
    </div>
  );
};

export default Kvkk;
