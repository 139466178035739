import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../assets/css/Footer.css";
import logo from "../assets/images/logo.png";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div id="footer" className="footer">
      <div className="footerLeft">
        <div>
          <div className="footerLink footerLinkOrange">Anasayfa</div>
          <a
            className="footerLink"
            href="#who"
            onClick={() => navigate("/#who")}
          >
            Biz Kimiz?
          </a>
          <a
            href="#why"
            className="footerLink"
            onClick={() => navigate("/#why")}
          >
            Neden Valesepeti?
          </a>
          
          <a
            href="#sss"
            className="footerLink"
            onClick={() => navigate("/#sss")}
          >
            S.S.S.
          </a>
          <a
            href="#contact"
            className="footerLink"
            onClick={() => navigate("/#contact")}
          >
            İletişim
          </a>
        </div>
        <div>
          <div className="footerLink footerLinkOrange">Platform</div>
          <Link style={{ textDecoration: "none" }} to="/privacy">
            <div className="footerLink">Gizlilik Politikası</div>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/kvkk">
            <div className="footerLink">KVKK</div>
          </Link>
         
          <Link to="/comingSoon" style={{ textDecoration: "none" }}>
            <div className="footerLink">İade Şartları</div>
          </Link>
          <Link to="/comingSoon" style={{ textDecoration: "none" }}>
            <div className="footerLink">Fiyatlar</div>
          </Link>
        </div>
      </div>
        <div className="footerRight">
          <Link to="/#home">
            <img className="footerLogoImg" src={logo} alt=""  />
          </Link>
        </div>
    </div>
  );
};

export default Footer;
