import React, { useEffect, useState } from "react";
import blackTelImg from "../assets/images/blackTelImg.png";
import home from "../assets/images/home.jpg";
import homeMobile from "../assets/images/homeMobile.jpg";

const Hero = () => {
  const [innerWidth, setInnerWidth] = useState();

  useEffect(() => {
    setInnerWidth(window.innerWidth);
  }, []);

  if (innerWidth && innerWidth < 768) {
    return (
      <div id="home" className="home reveal" style={{ position: "relative" }}>
        <h1>Yolculuğunuz <span className="orange">Valesepeti</span><br/>
        ile artık daha güvende!</h1>
        <img className="homeImgMobile" src={homeMobile} alt="" />
        <div
          className="telFlex"
          style={{ position: "absolute", bottom: "15vh", left: "20px" }}
        >
          <a className="whiteTelA" href="tel:05368942157">
            <img
              className="blackTelImg"
              src={blackTelImg}
              style={{ filter: "invert(1)" }}
              alt=""
            />
            0536 894 21 57
          </a>
        </div>
      </div>
    );
  }
  // WEB NAVBAR
  else {
    return (
      <div id="home" className="home reveal">
              <h1>Yolculuğunuz <span className="orange">Valesepeti</span><br/>
        ile artık daha güvende!</h1>

        <img className="homeImg" src={home} alt="" />
      </div>
    );
  }
};

export default Hero;
