import React from "react";
import "../assets/css/Privacy.css";
import { useNavigate } from "react-router-dom";
// import privacy from "../assets/images/privacy.png";

const Privacy = () => {
  const navigate = useNavigate();

  return (
    <div id="privacy">
      <h2 className="privacyTitle">Gizlilik Politikası</h2>
      <div className="privacyContainer">
        <br />
        <br />
        <p className="privacyUpTitle">Gizlilik Politikası</p> <br />
        <p>
          Valesepeti Turizm Taşımacılık ve Araç Kiralama Hizmetleri Ltd. Şti.
          (“Valesepeti”) olarak, Valesepeti mobil uygulaması kullanıcılarının
          (“Kullanıcı”) bilgilerinin korunmasına ve gizliliğine önem veriyoruz.
          Bu Gizlilik Politikası (“Politika”) Valesepeti’nin mobil uygulama
          aracılığıyla topladığı bilgileri nasıl kullandığı, kullanıcıların
          sorumlulukları ve Valesepeti’nin gizli bilgilerin korunmasına ilişkin
          kuralları hakkında kullanıcıları bilgilendirmeyi amaçlamaktadır.
        </p>
        <br />
        <p className="privacySubTitle">1. Topladığımız Bilgiler</p>
        <br />
        <p>
          Valesepeti mobil uygulamasını kullanmanız durumunda, üyelik kaydınızı
          gerçekleştirmek, talep ettiğiniz ürün/hizmetleri size temin etmek ve
          ödemeleri gerçekleştirmek gibi sair süreçler için belirli bilgileriniz
          toplanmaktadır.
          <br />
          Kullanıcılar, mobil uygulamayı kullanmak, talep, öneri ve
          şikayetlerini Valesepeti’ne iletmek istediklerinde veya sair
          nedenlerle Valesepeti’ne bilgilerini sağlamaktadır. Bu durumda,
          kullanıcıların ad-soyad, iletişim bilgileri, ödeme bilgileri, talep,
          öneri şikâyet bilgileri gibi belirli bilgileri toplanmaktadır.
          Valesepeti mobil uygulamasında, kullanıcıların mobil uygulama
          deneyimlerini en iyi şekilde gerçekleştirmelerini temin etmek için
          kullanıcılarının belirli hareketlerinin kayıt altına alınmasını ve
          cihaz bilgileri ile uygulamada gerçekleştirilen işlemleri
          eşleştirebilmemize imkan sağlayan tanımlama teknolojilerini
          kullanıyoruz. Tanımlama teknolojilerinin kullanımı ile ilgili bilgi
          için{" "}
          <span className="privacySpan" onClick={() => navigate("/kvkk")}>
            Tanımlama Teknolojileri Hakkında Bilgilendirme
          </span>{" "}
          dokümanını inceleyebilirsiniz.
        </p>
        <br />
        <p className="privacySubTitle">
          2. Bilgileri Kullanma Amaçlarımız
        </p>{" "}
        <br />
        <p>
          Valesepeti’nin, mobil uygulama üzerinden topladığı bilgiler başlıca
          aşağıda belirtilen amaçlarla kullanılmaktadır:
        </p>{" "}
        <br />
        <p>
          • Mobil uygulama aracılığıyla kullanıcılara hizmet sunmak, <br />•
          Kullanıcılar ile iletişime geçmek, kullanıcıların talep ve
          şikayetlerini sonuçlandırmak, <br />• Ödemelere ilişkin finans ve
          muhasebe süreçlerini yürütmek, <br />• Hizmetlerimizin tanıtımını
          sağlamak; reklam ve kampanya süreçlerini yürütmek, <br />• Kullanıcı
          geri bildirimlerine göre ürün ve hizmetlerimizi
          iyileştirmek/geliştirmek, <br />• Hizmetlerimizi kullanıcıların
          tercih, kullanım alışkanlıkları ve ilgi alanlarına göre özelleştirmek,
          <br />• Mobil uygulamadaki sistemsel sorunları tanımlanmak ve
          gidermek; bilgi güvenliği süreçlerini yürütmek, <br />• Tanımlama
          teknolojileri vasıtasıyla kullanıcı deneyimlerini ölçümlemek, mobil
          uygulama performansını geliştirmek
          <br />
          <br />
          Bu kısımda belirtilen amaçlar, mobil uygulama üzerinden toplanan
          bilgilerin genel kullanılma amaçlarını ifade etmektedir.
          Valesepeti’nin müşteri kişisel verilerini işleme amaçları ve kişisel
          verilerin işlenmesi hakkında diğer konularda bilgi almak için lütfen{" "}
          <span className="privacySpan" onClick={() => navigate("/kvkk")}>
            Müşteri Kişisel Verilerinin Korunması Politikası
          </span>
          ’nı inceleyiniz.
        </p>{" "}
        <br />
        <p className="privacySubTitle">
          3. Bilgilerinizin Üçüncü Kişilere Aktarılması
        </p>{" "}
        <br />
        <p>
          Mobil uygulama üzerinden sağladığınız bilgiler, yukarıda belirtilen
          amaçların gerçekleştirilebilmesi için üçüncü kişilere aktarılabilir.
          Bu kapsamda bilgileriniz, iş ortaklarımız ile paylaşılabilecektir.
          Bununla birlikte, yasal yükümlülüklerimizin yerine getirilebilmesi
          için bilgilerinizi paylaşmamız talep edildiğinde, bilgileriniz yetkili
          kişi, kurum ve kuruluşlar ile de paylaşılabilecektir. Kullanıcıların
          sağladığı bilgiler, kullanıcılara sunulan metinlerde belirtilen
          amaçlar ve hizmetin sunulabilmesi için gereken durumlar haricinde
          üçüncü kişilere aktarılmayacaktır.
        </p>{" "}
        <br />
        <p className="privacySubTitle">
          4. Bilgilerinizin Saklanması ve Korunması
        </p>{" "}
        <br />
        <p>
          Bilgileriniz, bilgileri toplama amacımızın gerektirdiği süre kadar
          saklanmaktadır. Mevzuatta bilgilerinizi saklamamız için özel bir süre
          öngörülüyor ise, bu süreye uygunluk sağlanmaktadır. Valesepeti,
          bilgilerinizin muhafaza edildiği sistemleri, sunucuları ve veri
          tabanlarını, veri güvenliği tehditlerine (siber saldırı, hash vb.)
          karşı korumak için gerekli teknik ve idari tedbirleri almaktadır.
          Ödeme altyapısı <span className="privacySpan">xxxxx</span> tarafından
          sağlanmaktadır. Valesepeti kredi kartının ilk altı hanesi ve son iki
          hanesini sistemlerinde muhafaza etmektedir. Kullanıcının mevcut
          üyeliklerini eşleştirmek ve buradaki kartlarını Valesepeti’nde ödeme
          aracı olarak kullanabilmesini sağlamak amacıyla uygulamaya kayıt
          olunan cep telefonu numarasıyla üyelik sorgulaması yapılır ve
          kullanıcının üyeliği varsa, ilgili kartın eklenmesi için kullanıcıya
          bilgi ve yönlendirmede bulunulur. Kullanıcı, Valesepeti tarafından
          mobil uygulama aracılığıyla sunulan hizmetlerden yararlanabilmek
          amacıyla kullandığı sistem erişim araçlarının (kullanıcı ismi, şifre
          vb.) güvenliği, saklanması, üçüncü kişilerin bilgisinden uzak
          tutulması ile ilgili tüm sorumluluğun tamamen kendisine ait olduğunu
          kabul etmektedir.
        </p>{" "}
        <br />
        <p className="privacySubTitle">
          5. Bilgilerinizin Doğruluğu ve Güncelliği
        </p>{" "}
        <br />
        <p>
          Kullanıcıların sağladığı bilgilerin doğruluğu ve güncelliği
          kullanıcıların sorumluluğundadır. Bilgilerinizin gerçeğe uygun olarak
          tarafımıza iletilmesi ve bilgilerinizde bir değişiklik olması
          durumunda güncellenmesi gerekmektedir. Valesepeti ile paylaşmış
          olduğunuz bilgileri, mobil uygulama üzerinden veya Valesepeti müşteri
          hizmetlerini arayarak dileğiniz zaman güncelleyebilirsiniz.
          Valesepeti’ne bir başkası adına bilgi sağladığınızda, bu bilgilerin
          doğru olduğunu, bilgileri Valesepeti ile paylaşma hakkına sahip
          olduğunuzu/bu konuda yetkilendirildiğinizi taahhüt etmiş sayılırsınız.
        </p>{" "}
        <br />
        <p className="privacySubTitle">
          6. Üçüncü Taraflara Yapılan Yönlendirmeler
        </p>{" "}
        <br />
        <p>
          Valesepeti mobil uygulaması, Valesepeti tarafından işletilmeyen üçüncü
          taraf bağlantılara yönlendirmeler yapabilir. İlgili bağlantılardan
          herhangi birini ziyaretiniz sırasında, ziyaret ettiğiniz üçüncü
          tarafın gizlilik politikaları ve kullanım koşullarına tabi olursunuz.
          Valesepeti söz konusu üçüncü tarafların politika ve uygulamalarından
          sorumlu değildir.
        </p>{" "}
        <br />
        <p className="privacySubTitle">
          7. Mobil Uygulamada ve Bu Politikada Yapılan Değişiklikler
        </p>{" "}
        <br />
        <p>
          Valesepeti mobil uygulama aracılığıyla sunulan hizmetleri, bilgileri,
          görselleri ve sair unsurları önceden bildirimde bulunmaksızın
          değiştirme, kullanımını askıya alma veya durdurma hakkını saklı
          tutmaktadır. Valesepeti, bu Politika üzerinde değişiklik ve güncelleme
          yapma hakkını saklı tutmaktadır. Politika’nın güncel versiyonunu takip
          etmek, kullanıcının sorumluluğundadır.
        </p>{" "}
        <br />
        <p className="privacySubTitle">8. Sorumluluk</p> <br />
        <p>
          Mobil uygulamanın kullanımı sırasında oluşabilecek herhangi bir hata,
          kesinti, gecikme, virüs, hat ve/veya sistem arızası sonucunda ortaya
          çıkabilecek doğrudan veya dolaylı zarar ve masraflardan Valesepeti
          sorumlu değildir.
        </p>{" "}
        <br />
        <br />
      </div>
    </div>
  );
};

export default Privacy;
