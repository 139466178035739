import React from "react";
import "../assets/css/Who.css";
import who from "../assets/images/who.png";
import star from "../assets/images/star.png";

const Who = () => {
  return (
    <div id="who" className="reveal">
      <div className="whoLeft">
        <img className="whoImg" src={who} alt="" />
      </div>
      <div className="whoRight">
        <h2 className="whoTitle">Biz Kimiz?</h2>
        <p>
          <span className="whoSpan">Valesepeti</span> geçmişte başka markalar
          altında aynı sektörde çalışmış ve bu konu üzerinde oldukça tecrübe
          edinmiş kişiler tarafından kurulmuş olan, sizleri ihtiyacınıza yönelik
          valeler ile buluşturan bir hayat kurtarıcıdır.
        </p>
        <div className="whoFlex">
          <img className="whoStar" src={star} alt="" />
          <h4>
            <b>Vale Personeli</b>
          </h4>
        </div>

        <p>
          <span className="whoSpan">Valesepeti</span>, ülkemizde uzun yıllardır
          sektörde olan ve birbirleri ile etkileşimde bulunan valeler ve vale
          firması sahipleri ile sizlere kurumsal hizmet sunar.
        </p>
        <div className="whoFlex">
          <img className="whoStar" src={star} alt="" />
          <h4>
            <b>Müşteri Memnuniyeti</b>
          </h4>
        </div>

        <p>
          Tüm sürücülerimiz temel sürüş eğitimlerine sahip olup, belirli
          aralıklarla İstasyon Sürücü Kursu aracılığı ile denetlenmektedir. Bu
          uygulama Türkiye’de sadece <span className="whoSpan">Valesepeti</span>
          ’nde yapılmaktadır.
        </p>
        <div className="whoFlex">
          <img className="whoStar" src={star} alt="" />
          <h4>
            <b>7/24 Hizmet</b>
          </h4>
        </div>

        <p>
          <span className="whoSpan">Valesepeti</span> uygulama üzerinden
          İstanbul içerisinde 7 gün 24 saat tek bir dokunuşla istediğiniz
          standarda ve işe uygun valeyi size optimum sürede ulaştırır.
        </p>
      </div>
    </div>
  );
};

export default Who;
